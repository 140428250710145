/* General Section */
.section-full{
    padding-top: 2rem;
}
.in-text{
    padding: 0rem;
    color: rgb(0, 174, 255);
    display: inline;
}

.header-link{
    color: white;
}

.project-button{
    display: block;
    text-align: center;
    margin-left: 55%;
    margin-top: -4%;
    background-color: rgb(255, 255, 255);
    width: 15rem;

    color: rgb(0, 102, 255);
    border-radius: 0.2rem;
}

/* Code Project Config */
#code-projects{
    padding-top: 1rem;
}
#code-projects-img{
    border: 1px solid rgb(0, 174, 255);
    max-width: 500px;
    width: 70%;
    height: auto;
}
#code-projects-text{
    border: 3px solid;
    padding-top: 10;
}

/* Art Project Config */
#art-projects-img{
    max-width: 500px;
    width: 70%;
    height: auto;
}

#art-projects-text{
    padding-top: 10;
    border: 3px solid
}

/* 3D Print/Model Project Config */
#tdpm-projects-img{
    border: 1px solid rgb(255, 0, 221);
    max-width: 500px;
    width: 70%;
    height: auto;
}
#tdpm-projects-text{
    border: 3px solid;
    padding-top: 10;
}
#tdpm-notes{
    color: rgb(255, 0, 221);;
}

