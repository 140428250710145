* {
    -ms-overflow-style: thin;  /* IE and Edge */
    scrollbar-width: thin;  /* Firefox */
    scrollbar-color: rgba(255, 255, 255, 0.5) transparent;
}

/* scrollbar chnages */
::-webkit-scrollbar {
    width: 9px;
}
::-webkit-scrollbar-track {
    background: transparent;
}
::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
    border: transparent;
}

/* Minor app structure changes */
h1 {
    font-size: 500%;
}
h2 {
    font-size: 200%;
}

body {
    overflow-x: hidden; /* Hide horizontal scrollbar */
    margin: 0;
    padding: 0;
}

/* for projects overview on home page */
.main-section{
    padding-top: 5rem;
}
.main-section-title {
    background-color: rgb(255, 255, 255);
    color: black;
    text-align: center;
    width: 50%;
    padding-left: 10rem;
    font-size: 175%;
}
.section-title{
    border: 1px solid rgb(255, 255, 255);
    margin: -.0001rem;
    padding: 2rem;
    text-align: center;
    width: 40%;
}
.section-full{
    border: 1px solid black;
    margin: 1rem;
    padding: 2rem 2rem;
    text-align: center;
    padding-bottom: 5rem;
}
.section-element{
    display: inline-block;
    border: 1px solid rgb(0, 255, 115);
    padding: 5rem;
    margin: -1rem;
    vertical-align: middle;
}

/* Mobile Config */
@media only screen and (max-width: 755px) {
    .section-title{
        min-width: 75%;
    }
}