/* General Section */
.page-header{
    font-size: xx-large;
}

.page-header-desc{
    margin: 1rem;
}

/* Mobile Config */
@media only screen and (max-width: 755px) {
    td, th {
        display: block;
        padding: 15% 0%;
    }
    
}