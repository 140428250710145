.home-bg-img {
    height: auto;
    width: 100%;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    /*padding-top: 30%;
    padding-bottom: 31%;*/
}