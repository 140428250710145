.project-tile{
    padding-bottom: 10%;
}

.tile-title{
    font-size: larger;
}

.tile-text{
    text-align: justify;
    padding-left: 5%;
    padding-right: 5%;
}

.tile-button{
    display: block;
    background-color: rgb(255, 255, 255);
    width: 15rem;
    color: rgb(0, 102, 255);
    margin-left: auto;
    margin-right: auto;
    border-radius: 0.2rem;
}