/* General */
a {
    text-decoration: none;
    display: block;
    padding: 1rem;
}

.site-navbar {
    background-color: black;
    /*border-bottom: 1px solid rgb(255, 255, 255);*/
    box-sizing: border-box;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Logo Elements (Left-Side) */
.navbar-logo{
    display: block;
    float: left;
}
.logo-img {
    padding-left: 1.75rem;
    padding-right: 1rem;
}
.logo-label {
    font-size: larger;
    font-weight: lighter;
}
#navbar-logo a {
    color: white;
    text-decoration: none;
}

/* Main Navbar Config */
ul {
    background: black;
    list-style: none;
    margin: 0;
    padding-left: 0;
}  
li {
    color: #fff;
    background: black;
    float: left;
    position: relative;
    text-decoration: none;
    transition-duration: 0.5s;
}

li a {
    color: #f2f2f2;
    display: block
}  
li:hover {
    background: #cecece;
    color: black;
    cursor: pointer;
} 
ul li ul {
    background: black;
    visibility: hidden;
    opacity: 0;
    min-width: 5rem;
    position: absolute;
    transition: all 0.5s ease;
    left: 0;
    display: none;
} 
ul li:hover > ul,
ul li ul:hover {
    visibility: visible;
    opacity: 1;
    display: block;
    background-color: #cecece;
    color: black;
} 
ul li ul li {
    clear: both;
    width: 100%;
}

.navbar-links a:hover {
    color: black;
}

.navbar-mobile-button{
    display:none;
}

/* Mobile Config */
@media screen and (max-width: 755px){
    .navbar-mobile-button{
        color: white;
        background-color: black;
        border: transparent;
        font-size: xx-large;
    }
    .navbar-links{
        display: none;
    }
    .navbar-links-mobile{
        position: absolute;
        display: block;
        list-style: none;
        background-color: rgb(31, 31, 31);
        left: 0;
        top: 75px;
        transition: all 0.5s ease-out;
        width: 100%;
    }
    .navbar-element{
        text-align: center;
        padding: 1% 0%;
        width: 100%;
        transition: all 0.5s ease;
    }
    .navbar-mobile-button{
        display: block;
        position: block;
        text-align: left;
    }
    ul li ul li {
        display: none;
    }
}