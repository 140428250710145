/* General Config*/
#about{
    padding-top: 3rem;
}
#sb-about{
    border: 1px solid black;
    margin: 1rem;
    padding: 2rem 2rem;
    text-align: center;
    padding-bottom: 25rem;
}

#sb-about-img{
    border: 1px solid rgb(251, 255, 0);
    max-width: 25rem;
    width: 70%;
    height: auto;
}
#sb-about-text{
    border: 3px solid;
}

#about-element-header{
    font-size: xx-large;
}
#about-element-header-name{
    color: rgb(251, 255, 0);;
}

